import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { Component, Vue, Watch } from 'vue-property-decorator'

@Component({
  name: 'GtrRegistrationModuleNewPromoCodeView'
})
export default class GtrRegistrationModuleNewPromoCodeView extends GtrSuper {
  data () {
    return {
      submitting: false,
      showGeneratePromoCodeFields: false,
      promoCode: {
        code: null,
        group_discount_type: 'ALL_GROUP_MEMBERS',
        discount_type: null,
        discount_amount: null,
        discount_percent: null,
        discount_fixed_price: null,
        cap: null,
        active_start_date: null,
        active_end_date: null,
        notes: null,
        reg_types: []
      },
      promoCodeGenerateItems: [
        'Single',
        'Multiple'
      ],
      promoCodeGenerateSingleOrMultiple: null,
      promoCodeMultipleQuantity: 2,
      regtype_specific_options: [
        {
          label: 'Yes',
          value: true
        },
        {
          label: 'No',
          value: false
        }
      ],
      group_style_options: [
        {
          label: 'All Group Members',
          value: 'ALL_GROUP_MEMBERS'
        },
        {
          label: 'Single Group Member',
          value: 'SINGLE_GROUP_MEMBER'
        }
      ],
      discount_type_items: [
        {
          label: 'Amount',
          value: 'AMOUNT'
        },
        {
          label: 'Percent',
          value: 'PERCENT'
        // },
        // {
        //   label: 'Fixed Price',
        //   value: 'FIXED'
        }
      ]
    }
  }

  async mounted () {
    try {
      await this.$store.dispatch('option/getOptionsGroup', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  @Watch('promoCodeGenerateSingleOrMultiple')
  handleSingleOrMultiplePromoCode (val) {
    if (val === 'Multiple') {
      this.$data.promoCode.code = ''
    }
  }

  private handleRegtypeSpecificChange (value: boolean) {
    if (!value) {
      this.$data.promoCode.reg_types = []
    }
  }

  async generateSinglePromoCode () {
    const code = await this.$store.dispatch('promocodes/generateSinglePromoCode', this.$route.params.event_uuid)
    this.$data.promoCode.code = code
  }

  get registration_types () {
    const option_groups = this.$store.state.option.option_groups
    if (Object.keys(option_groups).length === 0) {
      return []
    }
    const registration_types_array = option_groups
      .filter(group => group.name === 'Registration Types')
      .shift().options
    const registration_types: any = []
    for (let i = 0; i < registration_types_array.length; i++) {
      registration_types.push({
        label: registration_types_array[i].name,
        value: registration_types_array[i].uuid
      })
    }
    return registration_types
  }

  /**
   * V-combobox doesn't allow populating v-model with value strings without showing the selected value in the component
   * chips instead of the label
   */
  get promoCodeRegTypesAsValueString () {
    return this.$data.promoCode.reg_types.map(object => object.value)
  }

  async submit () {
    const form = (this.$refs.newPromoCodeForm as HTMLFormElement)
    if (form.validate()) {
      try {
        this.$data.submitting = true
        const payload: any = {
          event_uuid: this.$route.params.event_uuid,
          data: {
            code: this.$data.promoCode.code,
            discount_percent: this.$data.promoCode.discount_percent,
            discount_amount: this.$data.promoCode.discount_amount,
            discount_type: this.$data.promoCode.discount_type,
            group_discount_type: this.$data.promoCode.group_discount_type,
            discount_fixed_price: this.$data.promoCode.discount_fixed_price,
            cap: this.$data.promoCode.cap,
            active_start_date: this.$data.promoCode.active_start_date,
            active_end_date: this.$data.promoCode.active_end_date,
            regtype_specific: this.$data.promoCode.regtype_specific,
            reg_types: this.promoCodeRegTypesAsValueString,
            notes: this.$data.promoCode.notes
          }
        }
        if (this.$data.promoCodeGenerateSingleOrMultiple === 'Multiple') {
          delete payload.data.code
          payload.data.qty = this.$data.promoCodeMultipleQuantity
        }
        await this.$store.dispatch('promocodes/createPromoCode', payload)
        Container.get(Notification).success('Promo code successfully created.')
        this.$router.push({
          name: 'level-two.modules.registration.promo-codes'
        })
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.submitting = false
      }
    }
  }
}
